import * as actionType from "../constants/actionTypes";

const live_movie_viewReducer = (state = { currenttimeData: null }, action) => {
	switch (action.type) {
		
		case actionType.CURRENTTIME:
			return {...state,currenttime: action.payload};
	
			
		default:
			return state;
	}
};

export default live_movie_viewReducer;

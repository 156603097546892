import * as actionType from "../constants/actionTypes";

const adFormReducer = (state = { adFormData: null }, action) => {
	switch (action.type) {
		
		case actionType.ADFORMDETAILS:
			return {...state,adForm: action.payload};
			
		default:
			return state;
	}
};

export default adFormReducer;

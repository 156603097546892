import {
  MESSAGE,
  PROFILE,
  ADVERTISER,
  DISTRIBUTOR,
  RIGHTS,
  ROLE,
  LOGGEDIN,
  MESSAGE2,
  MESSAGE3,
  MESSAGE1,
  MESSAGE4,
} from "../constants/actionTypes";
import * as api from "../api/index.js";

export const login = (formData, navigate) => async (dispatch) => {
  try {
    const { data } = await api.login(formData);
    // console.log(data?.id?.first_time, "NewData");
    if (data?.statuscode == 200) {
      // console.log(data, JSON.stringify(data), "hiData");
      dispatch({ type: PROFILE, payload: data?.id });
      // dispatch({ type: ADVERTISER, payload:data?.advertiser[0] });
      // dispatch({ type: PRODUCER, payload:data?.producer[0] });

      // dispatch({ type: DISTRIBUTOR, payload:data?.distributor[0] });
      dispatch({ type: RIGHTS, payload:data?.rights[0] });  
      dispatch({ type: ROLE, payload: data?.id?.userType?.roleName });
      dispatch({ type: LOGGEDIN, payload: true });
      // localStorage.setItem("profile", JSON.stringify(data?.id));
      // localStorage.setItem("advertiser", JSON.stringify(data?.advertiser[0]));
      // localStorage.setItem("distributor", JSON.stringify(data?.distributor[0]));
      // localStorage.setItem("rights", JSON.stringify(data?.Rights[0]));
      // localStorage.setItem("role", JSON.stringify(data?.id?.userType?.roleName));
      // localStorage.setItem("loggedIn", "true");
      localStorage.setItem("loggedInDetails", JSON.stringify(data));
      localStorage.setItem("remember_me", formData?.remember_me);
      localStorage.setItem("loginDetails", JSON.stringify(formData));
      // navigate("/Dashboard",{state:{forceShow:true}})}
      // window.location.reload(false)
      // console.log(data,"lplp")
      if (data?.id?.first_time) {
        // console.log("NewTestinggg")
        navigate("/Dashboard", { state: { forceShow: true } });
      } else{
        // console.log("NewTestinggg12")
        navigate("/Authentication/ChangePassword", {
          state: { forceShow: true },
        });
      }
    }
    dispatch({ type: MESSAGE, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const forgot_password = (formData) => async (dispatch) => {
  try {
    const { data } = await api.forgot_password(formData);
    dispatch({ type: MESSAGE4, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const change_password = (formData, navigate) => async (dispatch) => {
  try {
    const { data } = await api.change_password(formData);
    dispatch({ type: MESSAGE3, payload: data });
    localStorage.setItem("remember_me", "false");
    localStorage.setItem("loginDetails", "{}");
    localStorage.setItem("loggedInDetails", "{}");
    dispatch({ type: PROFILE, payload: {} });
    dispatch({ type: ADVERTISER, payload: {} });
    dispatch({ type: DISTRIBUTOR, payload: {} });
    // dispatch({type:SUBADMIN,payload:{}})
    dispatch({ type: RIGHTS, payload: {} });
    dispatch({ type: ROLE, payload: "" });
    dispatch({ type: LOGGEDIN, payload: false });
    navigate("/", { state: { forceShow: true } });
  } catch (error) {
    console.log(error);
  }
};

export const verify_otp = (formData) => async (dispatch) => {
  try {
    const { data } = await api.verify_otp(formData);
    dispatch({ type: MESSAGE1, payload: data });
  } catch (error) {
    console.log(error);
  }
};
export const update_password = (formData) => async (dispatch) => {
  try {
    const { data } = await api.update_password(formData);
    dispatch({ type: MESSAGE2, payload: data });
  } catch (error) {
    console.log(error)
  }
}
import * as actionType from "../constants/actionTypes";

const adsubscriptionsReducer = (state = { adsubscriptionsData: null }, action) => {
	switch (action.type) {
		
		case actionType.ADSUBSCRIPTIONS:
			return {...state,ad_subscriptions: action.payload};
	
			
		default:
			return state;
	}
};

export default adsubscriptionsReducer;

import * as actionType from "../constants/actionTypes";

const paymentReducer = (state = { paymentData: null }, action) => {
	switch (action.type) {
		
		case actionType.RAZORPAYRES:
			return {...state,razorpayRes: action.payload};
	
			
		default:
			return state;
	}
};

export default paymentReducer;

import * as actionType from "../constants/actionTypes";

const productReducer = (state = { productData: null }, action) => {
  switch (action.type) {
      case actionType.PRODUCTS:
        return { ...state, products: action.payload };
        case actionType.PRODUCT : 
        return {...state,product:action.payload}
        
    default:
      return state;
  }
};

export default productReducer;

import * as actionType from "../constants/actionTypes";

const buyersReducer = (state = { buyersData: null }, action) => {
	switch (action.type) {
		
		case actionType.BUYERS:
			return {...state,buyers: action.payload};
			
		default:
			return state;
	}
};

export default buyersReducer;

import * as actionType from "../constants/actionTypes";

const orderReducer = (state = { orderData: null }, action) => {
	switch (action.type) {
		
		case actionType.ORDERS:
			return {...state,order_list: action.payload};
		case actionType.ORDER:
				return {...state,order: action.payload};
			
		default:
			return state;
	}
};

export default orderReducer;

import { combineReducers } from 'redux';
import masters from './masters';
import subscriptions from './subscriptions'
import advertisementspay from './advertisements_notInUse'
import customers from './customers'
import livestreamings from './livestreamings'
import merchandise from './merchandise'
import movies from './movies'
import report from "./report"
import transactions from "./transactions"
import watchhours from "./watchhours"
import webseries from './webseries';
import setting from './setting'
import dashboard from './dashboard'
import layout from "./layout"
import promotion from "./promotion"
import adForm from "./adForm"
import distributors from "./distributors"
import producer from './producer'
import reports from "./reports"
import otp from "./otpverify"
import setad from "./setadvertisement"
import bankDetails from "./bankdetails"
import currenttime from './live_movie_view';
import complaints from "./complaints"
import highlight from "./highlight"
import payment from "./payment"
import advertisers from "./advertisers"
import adsubscriptions from "./adsubscriptions"
import advertisement from "./advertisement"
import products from "./product"
import buyers from "./buyers"
import analytics from "./analytics"
import order from "./order"
import admin_advertisement from "./adminAdvertisement"
import posts from "./post"
export const reducers = combineReducers({ masters,products,order,buyers,admin_advertisement,advertisement,posts,adsubscriptions,analytics,advertisers,complaints,otp,distributors,payment,advertisementspay,highlight,layout,dashboard,reports,setting,subscriptions,customers,adForm,livestreamings,currenttime, setad ,merchandise,movies,report,transactions,bankDetails,promotion,producer,watchhours,webseries });

import * as actionType from "../constants/actionTypes";

const adminAdvertisementReducer = (state = { adminAdvertisementData: null }, action) => {
	switch (action.type) {
		
		case actionType.ADMINADVERTISEMENT:
			return {...state,admin_advertisement: action.payload};
	
			
		default:
			return state;
	}
};

export default adminAdvertisementReducer;
